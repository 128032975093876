// Tabs and accordions

// remove padding from tab container

.Tabs {
    padding: 0 !important;
    .nav-tabs {
        li.nav-item {
            font-size: 20px;
        }
    }
}

.verticalTabs {
    padding: 0 !important;
     .nav-item .nav-link {
        height: 4em;
        font-size: 18px;
        text-align: left;
        font-family: 'Roboto Condensed', sans-serif;
     }
div#v-pills-tabContent- {
    padding: 1em 2em;
}
 }

 div#horizontalTabsPanels- {
    overflow-x: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

 @media (min-width: 768px) {
    .verticalTabs {
        .nav {
            width: 25% ;
        }
    }
    }

    // tab quote page

    nav.nav.nav-pills.nav-justified {
        .nav-link {
            color: #000000;
            border: 1px solid var(--uc-primarybuttoncolor);
        }
            .nav-item.nav-link.active {
                color: #ffffff !important;
            }
    }
