
.product-list {
    .card.productCardWithDetail {
        margin-top: 0 !important;
    }
    button.accordion-button.addToQuote_dropdownBtn {
        border-radius: 0 !important;
        margin-left: -0.4px;
        background-color: #E5012D;
    }

    .productDetail-form button, .productCard-button-container button {
        margin-right: 0;
    }
    .accordion.accordion-cart {
        .accordion-body {
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; 
        }
    } 
    a.product-name h5 {
        font-size: 20px;
    }
}

.product-listing {
    padding-top: 1em !important;
    button.nav-link.filters-label {
        margin-top: 8px;
        left: 12px;
    }
    .sort-options {
        padding-bottom: 1rem !important;
        right: 0px;
    }
}

.filter-block {
    h4.border-bottom.pb-2.mb-3 {
        padding: 0 !important;
    }
    h4, h5 {
        color: var(--uc-bodytextcolor) !important;
    }
}

// Overwrite core search bar - adding margin top
.container.p-4 {
    .row.d-flex.flex-wrap {
        .input-group.input-group-lg.rounded-pill.flex-nowrap {
        margin-top: 2em;
       
        }
    }
}
