ul.mega-dropdown-list {
    display: flex;
    flex-direction: column;
    padding-top: 0 !important;
    .mega-dropdown-column {
        width: 100%;
        max-width: 25em;
        &:hover {
            font-weight: normal;
            text-decoration: none;
            background: #e5012d;
            }
        }
}
.mega-menu.dropdown-menu {
    width: -webkit-max-content;
    width: max-content;
    border-radius: 1px;

    .nav-link:hover {
        color: #ffffff !important;
        text-decoration: none;
        transition: auto;
    }
 
    ul.mega-dropdown-list {
        padding: 0;
        margin: 0;
        min-width: 15rem;
        a {
            font-size: 16px !important;
        }
    }
}

.nav-item.dropdown.menu-vertical:last-child .mega-menu.dropdown-menu {
    left: 0 !important;
}

// hamburger icon 

button.nav-link.align-items-end.d-lg-none.bg-transparent.border-0.collapsed {
    color: #000;
}

.nav-children {
        .nav-item {
            padding: 0px 8px !important;
        }
    .nav-link {
     color: var(--uc-textlinkcolor) !important;
    }
}

.nav-shop-all {
    padding: 5px 17px
}

.main-nav {
    img.img-fluid.navbar-brandLogo {
        min-width: 175px !important;
    }
    .d-block.d-lg-none.col-12 { //padding for search bar mobile
        padding-top: 1em;
    }
}

.header-ribbon {
    .nav-link:hover {
    color: var(--uc-primarybuttoncolor) !important;
}
}

//menu font size overwrites
.main-nav {
.nav-children{
        span.nav-link {
            font-size: 18px !important;

        }
        a.nav-link {
            font-size: 18px !important;
        }
    }
}

header.col-12 {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

nav.py-3.no-print.main-nav {
    padding: 1em 2em !important;
}

.accountMenu {
    a.nav-link.active {
        font-weight: bolder;
        font-family: 'Roboto';
    }
}

// Overwrite rounded 
.input-group.input-group-lg.rounded-pill.flex-nowrap {
    border-radius: 1px !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
    }
}