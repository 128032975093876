.imageSliderContain img {
        object-fit: contain !important;
}

.hero.content-slider.homeImageSlider {
        min-height: 0px;
    
.carousel-caption  a {
    position: absolute;
    bottom: 32%;
    background: #fff;
    left: 4.8%;
    padding: 0.6em 1.8em !important;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    font-family: 'Roboto Condensed';
    display: flex;
    align-items: center;
    @media (max-width: 767px){
        bottom: 22%;
        left: 5.5%;
    }
}

.carousel-item .overlay-carousel-caption{height:100%; margin: 0 !important;}

 .carousel-item .carousel-image {
    position: relative;
}

@media (max-width: 767px){
 .carousel-item .carousel-image {
    max-height: none;
    min-height: 270px;
}
}
.carousel-item .carousel-image img {object-position: left;}

 .carousel-caption {
    position: absolute;
}

}

.captionRedBoxLeft {
    .caption-container h2 {
        position: absolute;
        left: 0;
        bottom: 0;
        background: var(--uc-primarybuttoncolor);
        color: #fff;
        padding: 15px 26px !important;
        margin: 0;
    }
    .carousel-caption.overlay-carousel-caption {
        background-image: none !important;
    }
}