html {
  position: relative;
}

// style overwrites
.bodyContent.htmlContent.col-lg-12.col-12 {
  padding-left: 0;
  padding-right: 0;
}
.row.p-4 {
  padding: 1em 0em !important;
}

.productPrice_price > span:last-child{
  color: unset !important;
}

.cart-item-box h5.total-price, .cart-item-box .total-price.h5, .total-surcharge{
  line-height: unset !important;
}

// .container.pb-4.mb-2.mb-md-3.pt-4 {
//   padding-left: 0 !important;
// }
a.user-logout.btn.nav-link {
  line-height: inherit !important;
  font-size: inherit !important;
  text-align: inherit !important;
}

.paddingBottom {
  padding-bottom: 3em;
  .section-title h2 {
    text-align: left;
  }
}

body {
  max-width: 1280px !important;
  margin: 0 auto;
  //  padding:0 1rem;
}

.container {
  padding: 0 0.75rem !important;
}

// primary list style

.bodyContent.htmlContent.primary-list {
  padding: 0;
}
.primary-list > ul {
  li {
    list-style-type: square;
  }
  li::marker {
    color: #e5012d;
    font-size: 24px;
  }
}
// end list style

input,
select,
button,
.btn {
  border-radius: 0 !important;
}

// a.btn.btn-outline {
//     border: 1px solid #fff;
// }

.padding-0 {
  padding: 0 !important;
}
.col-gap-10 {
  column-gap: 10px;
}
a.btn.btn-outline:hover {
  background: #f8f9fa;
}

.nav-item.dropdown {
  list-style-type: none;
}

.logout-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.video-container {
  padding-bottom: 3rem;
}

/* swiper */
.grid-box {
  padding: 40px;
  background: #f7f7f7;
  width: 95%;
  height: 100%;
}

.section-swiper-time .swiper-wrapper {
  display: block;
}

@media (min-width: 768px) {
  .milestone-text {
    font-weight: bold;
    font-size: 18px !important;
    font-family: "Roboto Condensed", sans-serif;
  }
}

@media (min-width: 1200px) {
  .milestone-text {
    font-weight: bold;
    font-size: 24px !important;
    font-family: "Roboto Condensed", sans-serif;
  }
}

@media (max-width: 992px) {
  .grid-box{
    padding: 20px;
    width: 100%;
    height: auto;
  }

  .caption-container{
    margin-bottom: 0 !important;
  }
  .carousel-caption{
    margin: 0 !important;
    position: absolute;
    top:auto;
    max-height: 100vh;
    height: auto;
  }
  .swiper-slide {
    height: max-content !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .h-lg-1100 {
    min-height: 1100px;
  }

  .h-lg-1140 {
    min-height: 1300px;
  }

  .border-bottom-lg-none {
    border-bottom: 0 !important;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-container {
    padding-left: 3em !important;
    padding-right: 3em !important;
  }

  .section-swiper-time .swiper-wrapper {
    display: flex;
  }

  .section-swiper-time .swiper-wrapper::before {
    content: "";
    border-top: 1px solid #e5012f21;
    top: 50%;
    width: 800%;
    left: 0;
    right: 0;
    position: absolute;
  }

  .section-swiper-time .homepage-swiper-circular-down {
    top: 50%;
    position: absolute;
  }

  .section-swiper-time .homepage-swiper-circular-up {
    bottom: 50%;
    position: absolute;
  }

  .section-swiper-time .swiper-wrapper .swiper-slide::before {
    content: "";
    /* background: url('../images/swiper-arrowhead-left.svg'); */
    background-color: #e5012d;
    box-shadow: 0 0 6px 3px rgba(229, 1, 45, 0.3);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50.6%;
    left: 12%;
    transform: translate(-50%, -50%);
    margin-left: -7px;
    margin-top: -7px;
    z-index: 1;
  }

  .section-swiper-time
    .swiper-wrapper
    .swiper-slide
    .homepage-swiper-circular-up::before {
    content: "";
    /* background: url('../images/swiper-arrowhead-left.svg'); */
    background-color: #e5012d;
    width: 2px;
    height: 16px;
    position: absolute;
    bottom: 20px;
    left: 11.7%;
    margin-left: -7px;
    margin-top: -7px;
    z-index: 1;
  }

  .section-swiper-time
    .swiper-wrapper
    .swiper-slide
    .homepage-swiper-circular-down::before {
    content: "";
    /* background: url('../images/swiper-arrowhead-left.svg'); */
    background-color: #e5012d;
    width: 2px;
    height: 16px;
    position: absolute;
    top: 26px;
    left: 11.7%;
    margin-left: -7px;
    margin-top: -7px;
    z-index: 1;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px 15px;
    color: #000;
    font-size: 20px !important;
    position: absolute;
  }

  // .swiper-button-next:after{
  //   left: 40px;
  // }

  // .swiper-button-prev:after{
  //   right: 40px;
  // }

  .swiper-outer {
    position: relative;
  }
}

//toggle

// Colors
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #2f855a;
$white: #fff;
$focus-color: #ff0;

.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-colour;
    border-radius: 20px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }
    > span:focus {
      outline: none;
    }
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $toggle-colour;
      color: $white;
    }
  }
  &-disabled {
    background-color: $disabled-colour;
    cursor: not-allowed;
    &:before {
      background-color: $disabled-colour;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: $label-colour;
    color: $white;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid $label-colour;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: var(--uc-primarybuttoncolor) !important;
}

div:where(.swal2-icon).swal2-info {
  border-color: var(--uc-primarybuttoncolor) !important;
  color: var(--uc-primarybuttoncolor) !important;
}

.badge.bg-info {
  background-color: var(--uc-primarybuttoncolor) !important;
}

.table-quote-items th {
  font-weight: normal;
}

#csvAdd {
  th {
    font-size: 14px;
    padding: 20px 10px;
  }

  thead {
    background: #ddd;
    width: 100%;
  }

  input.form-control {
    font-size: 12px;
  }

  .btn-sm,
  .btn-group-sm > .btn {
    font-size: 12px;
    cursor: pointer;
  }

  input.form-control[type="number"] {
    width: 4rem;
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    font-size: 12px;
    padding: 15px 10px;
  }
}


.custom-select {
  &:disabled {
    background-color: #e9ecef;
  }
}

.rbt-loader{
  display: none;
}