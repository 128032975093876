.category-tile.header-left {
    header.section-title {
        h2 {
            margin-bottom: 0;
            text-align: left;
            font-size: 30px;
        }
    }
} 

#productPanelAccordion .accordion-body ul:not(.nav-tabs) {
    color: #000;
    list-style-type: square;
}

#productPanelAccordion .accordion-body ul li:not(.nav-item)::marker {
    color: #e5002c;
    font-size: 24px;
}

#paneljsontechnicalData table{ width: 100%; }
#paneljsontechnicalData table th{ text-align: center;}