// overwriting product card button style

.container.product-listing .product-name:hover {
    color: var(--uc-primarybuttoncolor);
}

.productCardWithDetail {
    .product-brand {
        height: auto !important;
        text-decoration: none;
    }
    .product-name {
        display: block !important;
    }
    .truncate_expandBtn {
        text-decoration: underline;
    }
    .truncate_content {
        font-size: 15px;
    }
}
.product-item {
    .product-title {
        a:not(header *, footer *, .btn, .product-name) {
            text-decoration: none;
            font-size: 28px;
            font-weight: bold;
            display: block;
        }
    }
    img.productImage.productImage.defaultImage {
        max-height: 200px;
    }
    .product-desc {
        max-width: 35rem;
    }
    img.productImage.productImage {
        max-height: 200px;
    }
}
#productPanelAccordion {
  button.accordion-button[aria-expanded=false]  {
    background: #ffffff !important;
    color: #000000 !important;
  }

  button.accordion-button[aria-expanded=true] {
    background-color: var(--uc-primarycolor) !important;
    color: var(--uc-primarybuttontextcolor) !important;
  }
}
