//About impro fluidtek

.section-about {
    .font-size-60 {
        font-size: 60px !important;
    }
    h5 {
        font-size: 22px;
        margin-top: 1em;
        font-weight: bold;
    }
}

.homeTextWidthConstrain {
    width: 65%;
    margin: auto;
    padding-bottom: 2em;
    p {
        font-family: 'Roboto';
        font-size: 22px;
        font-weight: 400;
        line-height:39.6px;
    }
    h2 {
        margin-bottom: 0.5em;
    }
        @media (max-width: 991px) {
        width: 100%;
    }
}
@media (max-width: 992px) {
    .action-banner-sec .btn-primary {
        font-size: 13px !important;
    }

    .footer .section-title h2 {
        font-size: 44px;
    }
}