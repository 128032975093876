// range listing - listing banner

div#collapseimage > img {
    border: none !important;
    object-fit: contain;
}

.container-fluid.listingBanner {
    padding: 1em;
    border: 1px solid #eee;
    border-radius: 1px;
        @media (max-width: 1399.99px) {
        border: none;
        }
}