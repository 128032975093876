.newsSection  {
    margin-bottom: 4em;
    header.section-title > h2 {
        margin-bottom: 2em;
}
}

    article.blog-card {
        a {
        text-decoration: none !important;;
        }
        .card-body {
            h3 {
                font-size: 22px;
                line-height: 1.2em;
            }
        }
    }

article.blog-card {
    .card-body {
        small.text-muted.fst-italic {
            font-style: normal !important;
            font-size: 12px;
            text-decoration: none;
        }
    }
}

    .blogTitleContainer {
        padding-top: 0 !important;
        h1.blogTitle {
            display: none;
            text-align: center;
        }
}
