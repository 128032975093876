.btn-primary {
  color: var(--uc-primarybuttoncolor);
  border: 2px solid var(--uc-primarybuttoncolor);
  font-size: 16px;
  text-decoration: none;
  background-color: transparent;
}

.btn-primary.active {
  &,
  &:hover {
    background-color: var(--uc-primarybuttoncolor);
    border-color: var(--uc-primarybuttoncolor);
    color: #ffffff;
  }
}

.btn-outline {
  color: var(--uc-primarybuttoncolor);
  border: 2px solid var(--uc-primarybuttoncolor) !important;
  font-size: 16px;
  text-decoration: none;
  background-color: transparent;
  font-size: 22px;
  &:hover {
    color: #ffffff;
    background-color: var(--uc-primarybuttoncolor) !important;
    border-color: #ffffff;
  }
  &::after {
    content: "\F138";
    padding-left: 15px;
    font-family: "bootstrap-icons";
  }
}
