.footer {
    .footer-ribbon {
        padding-top: 2em !important;
        .ribbon  {
        a.nav-link {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
        }
    }
    }

    .footer-container {
    a.nav-link {
        font-size: 18px;
        padding: 0 0.5em !important; // padding overwrite
    }

}
.row.footerTopRow {
    flex-direction: row;
        @media screen and (max-width: 991px) {
        flex-direction: column;
    }
}
}

footer.footer {
    padding: 1em;
}