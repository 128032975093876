.action-banner-sec {
  margin-bottom: 3em;
  padding: 3rem !important;
  h2 {
    font-size: 48px;
    margin-bottom: 2rem;
    padding-top: 0;
  }
  .btn-primary {
    border: 2px solid #ffffff;
    font-size: 22px !important;
    text-decoration: none;
    background-color: transparent;
    p{
        display: inline-flex;
    }
    &::after {
      content: "";
      display: none;
    }
    &:hover {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      p {
        color: #212529;
      }
    }
  }
  p {
    color: #ffffff;

    &::after {
      content: "\F138";
      padding-left: 15px;
      font-family: "bootstrap-icons";
    }
    &:hover {
      color: #212529;
    }
  }
}

.action-button {
  .btn {
   display: inline-flex;
  }
}
