.feature-banners {
  padding: 0 !important;

  h2 {
    text-align: left !important;
  }
  .card.overflow-hidden {
    min-height: 300px;
    margin-bottom: 2em;
    margin-top: 2em;
  }
  h5 {
    font-size: 32px;
    font-weight: 700;
  }
  .row.g-0 {
    padding: 2em;

    .col-md-6 {
      max-height: 400px !important;
      height: 400px;
      justify-content: center;
      display: flex;
      padding: 2em;
      .card-body {
        display: flex;
        flex-direction: column;
        text-align: left !important;
        h5.card-title {
          font-size: 28px;
        }
      }
    }
  }
}

.companyMilestoneOne .card .row {
  background: url(https://cms.improfluidtek.com/uploads/thumbnail_about_values_bg1_8fb8c6d4d7.jpg)
    no-repeat center center / cover;
  .card-title,
  .card-text {
    color: #ffffff;
  }
  .col-md-8 {
    display: flex;
  }
  .card-body {
    margin: auto;
    padding: 0;
  }
  @media (min-width: 992px) {
    .col-md-8 .card-body {
      margin-left: 150px !important;
      margin-right: 150px !important;
    }
  }
}

.companyMilestoneTwo .card .row {
  background: url(https://cms.improfluidtek.com/uploads/thumbnail_value2_6a965eb181.jpg)
    no-repeat center center / cover;
  .card-title,
  .card-text {
    color: #166e96 !important;
  }
  .col-md-8 {
    display: flex;
  }
  .card-body {
    margin: auto;
    padding: 0;
  }
  @media (min-width: 992px) {
    .card-body {
      margin-left: 50px !important;
      margin-right: 150px !important;
      margin-bottom: 150px !important;
      margin-top: 50px !important;
    }
    .col-md-4 {
      padding: 0;
      margin: 0px;
      position: absolute;
      right: 10%;
      bottom: 15%;
    }
  }
}

.companyMilestoneThree .card .row {
  padding: 0;
  background: #eeeeee;
  .col-md-8 {
    display: flex;
    padding: 2em;
  }
  .card-body {
    margin: auto;
    padding: 0;
  }
  @media (min-width: 992px) {
    .card-body {
      margin-left: 150px !important;
      margin-right: 150px !important;
    }
  }
}
.companyMilestoneFour .card .row {
  background: #21aacb;
  .card-title,
  .card-text {
    color: #ffffff;
  }
  .col-md-8 {
    display: flex;
  }
  .card-body {
    margin: auto;
    padding: 0;
  }
  @media (min-width: 992px) {
    .card-body {
      margin-left: 50px !important;
      margin-right: 150px !important;
    }
    .col-md-4 {
      padding: 30px !important;
    }
  }
}
