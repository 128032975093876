.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #212529;
    content: " > " !important;
    font-size: 1em;
}

li.breadcrumb-item > a {
    text-transform: uppercase;
    color: #212529;
}

ol.breadcrumb {
    background-color: #f8f9fa !important;
    padding: 1em;
}