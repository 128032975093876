
    .grid.content-container {
        grid-template-columns: repeat(2, 1fr) !important;
        .card.overflow-hidden.swBasicCard {
            box-shadow: 0px 0px 30px 0px #00000030;
            border-radius: 1px !important;
        }

          @media (max-width: 991px) {
            grid-template-columns: repeat(1, 1fr) !important;
            flex-direction: column;
          }
            h5.card-title.text-uppercase.font-weight-bold {
                font-size: 28px;
            }
                h2.btn.btn-primary {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: var(--uc-primarybuttoncolor);
                    color: #fff;
                    margin-top: 0 !important;
                    font-family: "Roboto Condensed", sans-serif;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 38.4px;
                }
                h4 {
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 33.6px;
                }
                p{
                font-family: 'Roboto';
                font-size: 15px;
                font-weight: 400;
                line-height: 27px;
                }
                a {
                    text-decoration: none;
                    &:hover {
                        color: unset;
                    }
                }

                    img.card-img-top.align-self-center {
                        height: 350px;
                    }
    }
