.resourceLinkListing {
        padding: 0 !important;
        margin-bottom: 2em;

    .row.justify-content-start.pb-4.px-4 {
        padding: 1em !important;
        .d-flex.flex-column.align-items-start.text-break { // overwrite flex direction
            flex-direction: row !important;
            gap: 2em;
            flex-wrap: wrap;
            @media (max-width: 768px) {
            display: grid !important;
            }
        }
    }

    a {
            color: #666666;
            border-color: #666666;
            border: 2px solid #666666;
            text-decoration: none !important;
            width: fit-content;
            min-width: 12em;
            text-align: center;
            padding: 10px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
            &::after {
                    padding-left: 10px;
                    content: "\F30A";
                    font-family: "bootstrap-icons";
                }
            }
            h3 {
                color: var(--uc-primarybuttoncolor);
                font-size: 22px !important;
                width: auto;
                width: -moz-fit-content;
                min-width: 6em;
                font-weight: 700;
        }
        header.section-title {
            h2{
                text-align: left;
            }
        }
    }


