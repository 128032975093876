@font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/roboto-condensed/regular.ttf")format("truetype");
    font-weight:400;
    font-display:swap;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/black.ttf")format("truetype");
    font-weight:800;
    font-display:swap;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/regular.ttf")format("truetype");
    font-weight:regular;
    font-display:swap;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/light.ttf")format("truetype");
    font-weight:200;
    font-display:swap;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/medium.ttf")format("truetype");
    font-weight:500;
    font-display:swap;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/thin.ttf")format("truetype");
    font-weight:100;
    font-display:swap;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/bold.ttf")format("truetype");
    font-weight:600;
    font-display:swap;
  }
  
  
  
  body {
     font-family: 'Roboto', sans-serif;
  }
  
  h1,h2,h3,h4,h5,h6{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    line-height: 1.2;
    }

    a.nav-link, span.nav-link {
    font-family: 'Roboto Condensed', sans-serif;
    }

  h2 { 
    font-size: 48px;
    font-weight: 700;
}
h1 {
    font-size: 52px;
}

.section-title h2 {
    font-size: 48px;
    font-weight: 700;
}

 p {
    line-height: 1.8;
 }

 a:hover {
    color:var(--uc-primarybuttoncolor) ;
}