.category-tile {
    padding: 2.5rem 0rem 2.5rem 0rem;
    @media (max-width: 768px) {
        padding: 1rem;
    }
    header.section-title {
        padding-bottom: 2em;
    }

    p.category-tile-card-title {
        font-size: 28px!important;
        font-weight: 700!important;
        font-family: "Roboto Condensed",sans-serif;
        line-height: 1.2;
        padding: 0.5em;
    }
}