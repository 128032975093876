.card-header {
    button.btn.btn-link.link-btn {
        background: none;
        color: #333;
    }
}
.font-size-sm.item-sku {
    font-size: 0.8rem;
}

.row.detail {
    grid-template-rows: auto;
}