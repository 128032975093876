section.content-columns-sec.cetColumns {
    padding: 0 !important;

    .column-container {
        cursor: auto;
        .sec-style {
            cursor: auto;
            height: auto;
        }
            h2 {
                text-align: left;
            }
                .sec-style-content {
                    top: unset;
                    bottom: 0%;
                    background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 60%));
                    background-color: unset !important;
                    h3 {
                        font-size: 28px !important;
                        font-weight: 700 !important;
                    }
                }
                h3.mb-3.mt-2.fw-light.text-center.fs-5.fw-bold.text-white {
                    padding-top: 0;
                    margin-bottom: 10px !important;

                }
            }

            .cetColumnsItem {
                @media screen and (max-width: 992px) {
                width: 100% !important;
                }
            }
}
