@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300&family=Italiana&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

header nav {
  .d-none.d-lg-flex.justify-content-end.col-9 {
    width: 550px;
  }

  form {
    width: 100%;

    .predictive-search-container {
      .d-flex.flex-column {
        a {
          border-top: 1px solid #eee;
        }
      }

      .products-results-container {
        display: none;
      }

      .search-card-image {
        aspect-ratio: 1 / 1;
        object-fit: contain;
      }

    }

    button.btn.btn-secondary.brand-navy {
      // button style overwrite before overwriting component
      color: var(--uc-primarybuttoncolor);
      border: 1px solid var(--uc-primarybuttoncolor) !important;
      text-decoration: none;
      background-color: transparent;
      font-size: 14px;
    }

    hr {
      display: none;
    }
  }
}

.searchbar-wrapper {
  max-width: 700px;
  margin: 0 auto;
}

.predictive-search-container {

  width: 300%;
  max-width: 920px;

  &.predictive-search-container--column {
    width: 200%;
  }

  &>div{
    flex-basis: 33.33%;
    &.ps-2 {
      flex-basis: 66.66%;
    }
  }

  a {
    text-decoration: none;
  }

  .search-product {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    position: relative;
    img{
      width: 100px;
      position: absolute;
      height: 100px;
    }
    .search-detail {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      padding-left: 110px;
      justify-content: center;
      .search-product-name{
        font-weight: 600;
        font-size: 15px;
      }
      .search-product-code{
        color: #999;
        font-size: 13px;
        padding: 5px 0 0;
      }
      ul{
        padding: 0;
        margin: 0;
        font-size: 14px;
        li{
          list-style: none;
          padding: 0;
          .product-modifier-title {
            color: #999;
          }
          &:first-child {
            padding-top: 5px;
          }
        }
      }
    }
    &:hover *{
      color: var(--uc-primarybuttoncolor) !important;
    }

    .search-suggestions{
      font-family: "Roboto Mono", monospace;
    }
  }

}

.quick-add-tab .predictive-search-container {
  width: 100%;
  img{
    width: 50px;
    height: 50px;
  }
  .search-detail{
    padding-left: 60px;
  }
  .products-header {
    display: none;
  }
}

header+.container .predictive-search-container{
  max-width: 700px;
  &>div:not(.ps-2) {
    display: none;
  }
  .ps-2 {
    border: none;
  }
}

.main-nav {  
  .search-detail {
    .search-product-code {
      display: none;
    }
    ul.search-suggestions {
      display: none;
    }
  }
}